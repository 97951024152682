<template>
  <b-container class="my-4 py-2 archive-page">
    <loader full :visible="isLoading" />
    <h2 class="mb-4">{{ $t('select_numbers') }}</h2>
    <b-row>
      <b-col cols="12" md="6">
        <div class="img-wrapper" v-if="selectedNewspaper">
          <img :src="baseUrl + selectedNewspaper.image.url" alt="Poster" />
          <div class="img-num">
            {{ selectedNewspaper.number }} {{ $t('num') }}
          </div>
        </div>
        <div v-else>
          {{ $t('select_number') }}
        </div>
      </b-col>
      <b-col cols="12" md="6" class="mt-5 mt-md-0">
        <div class="d-flex flex-column mb-4">
          <div class="mb-2">{{ $t('year') }}</div>
          <b-form-select v-model="year" :options="years" />
        </div>
        <div class="d-flex flex-column">
          <div class="mb-2">{{ $t('num') }}</div>
          <b-form-radio-group
            v-if="numOptions.length > 0"
            id="btn-radios-2"
            v-model="selectedNewspaper"
            :options="numOptions"
            buttons
            button-variant="outline-primary"
          />
          <div v-else>
            {{ $t('not_found') }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  components: {
    Loader
  },
  data: () => ({
    isLoading: false,
    year: new Date().getFullYear(),
    years: [],
    selectedNewspaper: null,
    newspapers: []
  }),
  computed: {
    numOptions() {
      return this.newspapers
        .filter(n => n.year === this.year)
        .map(n => ({
          text: n.number,
          value: n
        }))
    }
  },
  created() {
    this.isLoading = true
    for (let i = 2009; i <= new Date().getFullYear(); i++) {
      this.years.push({ value: i, text: i })
    }
    this.$store
      .dispatch('getNewspapers')
      .then(newspapers => {
        this.newspapers = newspapers
        this.selectedNewspaper = newspapers.filter(n => n.year === this.year)[0]
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/_vars';

.archive-page {
  .img-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    border-radius: 2px;
    .img-num {
      right: 20px;
      top: 20px;
      z-index: 2;
      position: absolute;
      color: $accent;
      background: rgba(231, 192, 111, 0.6);
      padding: 4px 8px;
      font-weight: bold;
      border-radius: 2px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .btn-group {
    flex-wrap: wrap;
    margin: -5px;
    .btn {
      border-radius: 0.25rem !important;
      flex: auto;
      margin: 5px !important;
    }
  }
}
</style>
